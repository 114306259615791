import "./App.css";
import { Header } from "./components/header/Header";
import {
  MachineryData,
  EnvironmentData,
  FarmLifeData,
  AddTheSoundOfMissingDigitalizationData,
} from "./data";
import { SoundsBox } from "./components/sounds-box/SoundsBox";
import { Footer } from "./components/footer/Footer";
import { useRef, useState } from "react";
import MaxContentWrapper from "./components/max-content-wrapper/MaxContentWrapper";

function App() {
  const machineryRef = useRef(null);
  const environmentRef = useRef(null);
  const farmLifeRef = useRef(null);
  const digitalizationRef = useRef(null);

  const [activeFilter, setActiveFilter] = useState("Machinery");
  const [isMuted, setIsMuted] = useState(false);

  const scrollToComponent = (ref, filterName) => {
    setActiveFilter(filterName);
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className="App">
      <body>
        <Header setIsMuted={setIsMuted} isMuted={isMuted} />
        <MaxContentWrapper>
          <ul className="filters">
            <li
              className={`filter-title ${
                activeFilter === "Machinery" ? "active" : ""
              }`}
              onClick={() => scrollToComponent(machineryRef, "Machinery")}
            >
              Machinery
            </li>
            <li
              className={`filter-title ${
                activeFilter === "Environment" ? "active" : ""
              }`}
              onClick={() => scrollToComponent(environmentRef, "Environment")}
            >
              Environment
            </li>
            <li
              className={`filter-title ${
                activeFilter === "FarmLife" ? "active" : ""
              }`}
              onClick={() => scrollToComponent(farmLifeRef, "FarmLife")}
            >
              FarmLife
            </li>
            <li
              className={`filter-title ${
                activeFilter === "Digitalization" ? "active" : ""
              }`}
              onClick={() =>
                scrollToComponent(digitalizationRef, "Digitalization")
              }
            >
              Digitalization
            </li>
          </ul>
        </MaxContentWrapper>
        <div className="sound-boxes-wrapper">
          <div ref={machineryRef}>
            <SoundsBox
              data={MachineryData}
              title={"Machinery"}
              isColorPink={false}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
          </div>
          <div ref={environmentRef}>
            <SoundsBox
              data={EnvironmentData}
              title={"Environment"}
              isColorPink={false}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
          </div>
          <div ref={farmLifeRef}>
            <SoundsBox
              data={FarmLifeData}
              title={"FarmLife"}
              isColorPink={false}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
          </div>
          <div ref={digitalizationRef}>
            <SoundsBox
              data={AddTheSoundOfMissingDigitalizationData}
              title={"Missing digitalization"}
              isColorPink={true}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
          </div>
        </div>
        <MaxContentWrapper>
          <div className="link-wrapper">
            <h1 className="link-text">
              Want to avoid <span className="pink-color">this</span>? :-)
              <br />
              {`=> `}Contact us at
            </h1>
            <a href="https://dev4agriculture.de/" className="link">
              www.dev4agriculture.de
            </a>
          </div>
        </MaxContentWrapper>
        <Footer />
      </body>
    </div>
  );
}

export default App;
