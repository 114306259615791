export const MachineryData = [
  {
    filename: "sounds/baler.mp3",
    breaks: "0",
    name: "Baler",
    volume: 50,
  },
  {
    fileName: "sounds/transport_wagon.mp3",
    breaks: "100",
    name: "Transport wagon",
    volume: 50,
  },
  {
    fileName: "sounds/chopper.mp3",
    breaks: "200",
    name: "Chopper",
    volume: 50,
  },
  {
    fileName: "sounds/tractor.m4a",
    breaks: "100",
    name: "Tractor",
    volume: 50,
  },
];

export const EnvironmentData = [
  {
    fileName: "sounds/thunder.mp3",
    breaks: "0",
    name: "ThunderStorm",
    volume: 50,
  },
  {
    fileName: "sounds/wind-in-maize.mp3",
    breaks: "0",
    name: "Wind in the maize field",
    volume: 50,
  },
  {
    fileName: "sounds/church-bell.mp3",
    breaks: "900",
    name: "Church in a distance",
    volume: 30,
  },
];

export const FarmLifeData = [
  {
    fileName: "sounds/small-dog-barking.mp3",
    breaks: "0",
    name: "Farm Dog",
    volume: 100,
  },
  {
    fileName: "sounds/cows.mp3",
    breaks: "1000",
    name: "Cows",
    volume: 50,
  },
  {
    fileName: "sounds/cowbells.mp3",
    breaks: "1000",
    name: "CowBells",
    volume: 20,
  },
  {
    fileName: "sounds/rooster.wav",
    breaks: "1000",
    name: "Rooster",
    volume: 20,
  },
  {
    fileName: "sounds/chicken.mp3",
    breaks: "1000",
    name: "Chicken",
    volume: 20,
  },
];

export const AddTheSoundOfMissingDigitalizationData = [
  {
    fileName: "sounds/phone.mp3",
    breaks: "1000",
    name: "No fleet management",
    volume: 40,
  },
  {
    fileName: "sounds/broken-isoxml.mp3",
    breaks: "0",
    name: "Broken ISOXML",
    volume: 100,
  },
  {
    fileName: "sounds/no-apis.mp3",
    breaks: "0",
    name: "Missing Connectivity to the authorities",
    volume: 100,
  },
  {
    fileName: "sounds/wrong-application-map.mp3",
    breaks: "0",
    name: "Spraying a wrong application map",
    volume: 100,
  },
  {
    fileName: "sounds/usb-data-exchange.mp3",
    breaks: "0",
    name: "Still using USB sticks",
    volume: 100,
  },
];
