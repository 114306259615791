import React, { useState } from "react";
import "./SoundsBox.css";
import { arrowIcon } from "../../images";
import { AudioPlayer } from "../../AudioPlayer";
import MaxContentWrapper from "../max-content-wrapper/MaxContentWrapper";

export const SoundsBox = ({
  title,
  data,
  isColorPink,
  isMuted,
  setIsMuted,
}) => {
  const [isArrowClicked, setIsArrowClicked] = useState(true);

  const onArrowClick = () => {
    setIsArrowClicked(!isArrowClicked);
  };

  return (
    <MaxContentWrapper>
      <div className="sounds-box-wrapper">
        <div className="sounds-box-header">
          <span className={isColorPink ? "pink-color" : "title"}>{title}</span>
          <img
            style={{
              transform: isArrowClicked ? "rotate(270deg)" : "rotate(90deg)",
              transition: "transform 0.2s ease-in-out",
            }}
            onClick={onArrowClick}
            className="arrow-icon"
            src={arrowIcon}
            alt="arrow-icon"
          />
        </div>
        <div
          className={`audio-players-wrapper ${
            isArrowClicked ? "open" : "closed"
          }`}
          style={{
            padding: isArrowClicked ? "30px 30px" : "0 10px",
            transition: "padding 0.2s ease-in-out",
          }}
        >
          {data?.map((item) => {
            return (
              <AudioPlayer
                key={item.fileName}
                fileName={item.fileName}
                breaks={item.breaks}
                name={item.name}
                volume={item.volume}
                isColorPink={isColorPink}
                isMuted={isMuted}
                setIsMuted={setIsMuted}
              />
            );
          })}
        </div>
      </div>
    </MaxContentWrapper>
  );
};
