import React from "react";
import "./Footer.css";
import MaxContentWrapper from "../max-content-wrapper/MaxContentWrapper";

export const Footer = () => {
  return (
    <div className="footer-wrapper">
      <MaxContentWrapper>
        <div className="contact-info">
          <p className="footer-text">
            This service is brought to you by
            <a
              style={{ color: "#15AE6B", marginLeft: "3px" }}
              href="https://dev4agriculture.de/"
            >
              dev4Agriculture
            </a>
          </p>
        </div>
      </MaxContentWrapper>
    </div>
  );
};
