import React, { useState, useEffect, useRef } from "react";
import { speakerIcon, graySpeakerOff } from "./images";

export const AudioPlayer = ({
  name,
  fileName,
  breaks,
  volume,
  isColorPink,
  isMuted,
  setIsMuted,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentVolume, setCurrentVolume] = useState(volume / 100);

  const audioRef = useRef(new Audio(fileName));
  const audio = audioRef.current;

  const backgroundColor = isColorPink ? "#3F1627" : "#052617";

  useEffect(() => {
    audio.volume = currentVolume;
  }, [currentVolume, audio]);

  useEffect(() => {
    let timer;

    function playAudioWithBreaks() {
      audio
        .play()
        .then(() => {
          timer = setTimeout(() => {
            if (isPlaying) playAudioWithBreaks();
          }, audio.duration * 1000 + breaks);
        })
        .catch((error) => {
          console.error("Audio play error:", error);
        });
    }

    if (isPlaying) {
      playAudioWithBreaks();
    } else {
      audio.pause();
    }

    return () => {
      clearTimeout(timer);
      audio.pause();
      audio.currentTime = 0;
    };
  }, [isPlaying, audio, breaks]);

  const handleVolumeChange = (e) => {
    const volumeValue = e.target.value;
    setCurrentVolume(volumeValue);
    audio.volume = volumeValue;

    // audio.pause();
    // setIsPlaying(false);
  };

  const onSpeakerButtonClick = () => {
    setIsMuted(false);
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (isMuted) {
      setIsPlaying(false);
    }
  }, [isMuted, isPlaying]);

  return (
    <div className="audio-player-container">
      <span className="audio-name">{name}</span>
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        className={isColorPink ? "custom-range-pink" : "custom-range"}
        value={currentVolume}
        onChange={handleVolumeChange}
        style={{
          background: `linear-gradient(to right, ${backgroundColor} ${
            currentVolume * 100
          }%, #000000 ${currentVolume * 100}%)`,
        }}
      />
      <img
        onClick={onSpeakerButtonClick}
        className="gray-speaker"
        src={isPlaying ? speakerIcon : graySpeakerOff}
        alt="speaker"
      />
    </div>
  );
};
