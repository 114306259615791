import React from "react";
import "./Header.css";
import {
  dLogo,
  graySpeakerOff,
  soundsOfSillage,
  speakerIcon,
} from "../../images/index";
import MaxContentWrapper from "../max-content-wrapper/MaxContentWrapper";

export const Header = ({ setIsMuted, isMuted }) => {
  const onMuteButtonClick = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div className="header-wrapper">
      <MaxContentWrapper>
        <div className="header-button-wrapper">
          <img src={soundsOfSillage} alt="logo" style={{ width: "300px" }} />
          <img
            onClick={onMuteButtonClick}
            className="speaker-icon"
            src={isMuted ? graySpeakerOff : speakerIcon}
            alt="speaker-icon"
          />
        </div>
        <div className="web-icon-text-wrapper">
          <h2 className="header-heading">
            <p>by</p>
          </h2>
          <img className="d-logo" src={dLogo} alt="d-logo" />
        </div>
        <div className="flex-wrapper">
          <p className="header-heading-second">
            Create your perfect farm soundscape—mix of machines, animals, and
            nature.
            <br />
            Mix the sounds you like and enjoy your agricultural focus time!
            &#128512;
          </p>
        </div>
      </MaxContentWrapper>
      <div className="underline"></div>
    </div>
  );
};
